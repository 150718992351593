<template>
  <Popover class="fixed left-0 w-full top-0 z-20 bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div class="flex justify-between items-center py-8 md:justify-start md:space-x-5">
        <div class="flex justify-start lg:w-0 flex-1">
          <router-link to="/" 
            v-on:click="openSearch = false;">
            <span class="sr-only">Rescon</span>
            <div class="flex items-center">
              <img class="h-6 w-auto" src="@/assets/logo.svg" alt="Rescon" />
            </div>
          </router-link>
        </div>
        <div class="-mr-2 -my-2 lg:hidden">
          <PopoverButton
            class="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 transition hover:bg-white"
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6 text-accent" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden lg:flex space-x-6 xl:space-x-7">
          <NavItem
            v-for="item in navigation"
            :path="item.path"
            :key="item.label"
            :dropdownItems="item.dropdownItems"
            v-on:click="openSearch = false;"
          >
            {{ item.label }}
          </NavItem>
          <a class="text-accent cursor-pointer" v-on:click="openSearch = true;" v-if="!openSearch">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </a>
          <a class="text-accent  cursor-pointer" v-on:click="openSearch = false;" v-if="openSearch">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </a>
        </PopoverGroup>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right lg:hidden"
      >
        <div
          class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50" style=" height: 96vh; "
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <router-link to="/">
                <img class="h-5 w-auto" src="@/assets/logo.svg" alt="Rescon" />
              </router-link>
              <div class="-mr-2">
                <PopoverButton
                  class="rounded-md p-2 inline-flex items-center justify-center transition hover:bg-accent-lightest"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6 text-accent" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6" style=" height: 80vh; overflow: auto; ">
              <!-- <nav class="grid gap-y-8">
                <PopoverButton>
                  <router-link
                    v-for="item in navigation"
                    :key="item.label"
                    :to="item.path"
                    class="nav-item-mobile"
                  >
                    <span class="nav-item-mobile__text">{{ item.label }}</span>
                  </router-link>
                </PopoverButton>
              </nav> -->
              <nav class="grid">
                <PopoverButton>
                  <router-link to="/" class="nav-item-mobile">
                    <span class="nav-item-mobile__text">Home</span>
                  </router-link>
                </PopoverButton>

                <!-- ABOUT -->
                <Disclosure v-slot="{ open }">
                  <DisclosureButton class="cursor-pointer">
                    <router-link to="/about" class="nav-item-mobile pointer-events-none flex">
                      <span class="nav-item-mobile__text">About Us</span>
                      <ChevronUpIcon
                        :class="open ? 'transform rotate-180' : ''"
                        class="w-5 h-5 text-accent ml-auto"
                      />
                    </router-link>
                  </DisclosureButton>
                  <DisclosurePanel class="ml-2 grid">
                    <PopoverButton v-for="section in aboutSections" :key="section.href">
                      <router-link :to="section.href" class="nav-item-mobile">
                        <span class="nav-item-mobile__text">{{ section.label }}</span>
                      </router-link>
                    </PopoverButton>
                  </DisclosurePanel>
                </Disclosure>

                <Disclosure v-slot="{ open }">
                  <DisclosureButton class="cursor-pointer">
                    <router-link to="/about" class="nav-item-mobile pointer-events-none flex">
                      <span class="nav-item-mobile__text">Anti-Racism Roundtable</span>
                      <ChevronUpIcon
                        :class="open ? 'transform rotate-180' : ''"
                        class="w-5 h-5 text-accent ml-auto"
                      />
                    </router-link>
                  </DisclosureButton>
                  <DisclosurePanel class="ml-2 grid">
                    <PopoverButton v-for="section in antiracismSections" :key="section.href">
                      <router-link :to="section.href" class="nav-item-mobile">
                        <span class="nav-item-mobile__text">{{ section.label }}</span>
                      </router-link>
                    </PopoverButton>
                  </DisclosurePanel>
                </Disclosure>
                <Disclosure v-slot="{ open }">
                  <DisclosureButton class="cursor-pointer">
                    <router-link to="/about" class="nav-item-mobile pointer-events-none flex">
                      <span class="nav-item-mobile__text">COVID-19</span>
                      <ChevronUpIcon
                        :class="open ? 'transform rotate-180' : ''"
                        class="w-5 h-5 text-accent ml-auto"
                      />
                    </router-link>
                  </DisclosureButton>
                  <DisclosurePanel class="ml-2 grid">
                    <PopoverButton v-for="section in covidSections" :key="section.href">
                      <router-link :to="section.href" class="nav-item-mobile">
                        <span class="nav-item-mobile__text">{{ section.label }}</span>
                      </router-link>
                    </PopoverButton>
                  </DisclosurePanel>
                </Disclosure>
                <!-- MEDIA -->
                <Disclosure v-slot="{ open }">
                  <DisclosureButton class="cursor-pointer">
                    <router-link to="/media" class="nav-item-mobile pointer-events-none flex">
                      <span class="nav-item-mobile__text">Media</span>
                      <ChevronUpIcon
                        :class="open ? 'transform rotate-180' : ''"
                        class="w-5 h-5 text-accent ml-auto"
                      />
                    </router-link>
                  </DisclosureButton>
                  <DisclosurePanel class="ml-2 grid">
                    <PopoverButton v-for="section in mediaSections" :key="section.href">
                      <router-link :to="section.href" class="nav-item-mobile">
                        <span class="nav-item-mobile__text">{{ section.label }}</span>
                      </router-link>
                    </PopoverButton>
                  </DisclosurePanel>
                </Disclosure>
                <PopoverButton>
                  <router-link to="/submissions" class="nav-item-mobile">
                    <span class="nav-item-mobile__text">Submissions</span>
                  </router-link>
                </PopoverButton>
                <PopoverButton>
                    <router-link to="/events" class="nav-item-mobile">
                      <span class="nav-item-mobile__text">events</span>
                    </router-link>
                </PopoverButton>
                <PopoverButton>
                  <router-link to="/orcca" class="nav-item-mobile">
                    <span class="nav-item-mobile__text">ORCCA</span>
                  </router-link>
                </PopoverButton>
                <PopoverButton>
                  <router-link to="/collective-agreements" class="nav-item-mobile">
                    <span class="nav-item-mobile__text">collective agreements</span>
                  </router-link>
                </PopoverButton>

                <PopoverButton>
                  <router-link to="/contact" class="nav-item-mobile">
                    <span class="nav-item-mobile__text">Contact</span>
                  </router-link>
                </PopoverButton>
              </nav>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <div class="fixed w-full border-t border-gray-100  z-20 top-24 bg-gray-50 transition"  :class="openSearch ? 'border-b' : ''" v-if="openSearch"  role="dialog" aria-modal="true">
    <div class="max-w-7xl mx-auto grid grid-cols-12 py-6">
      <div class="col-span-10 my-auto transform divide-y divide-gray-100 overflow-hidden rounded-xl rounded-r-none bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all w-full">
        <div class="relative">
          <!-- Heroicon name: solid/search -->
          <svg class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-accent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
          <input  v-if="openSearch" type="text" autofocus v-model="searchText" class="search h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-base" placeholder="Search for..." role="combobox" aria-expanded="false" aria-controls="options">
        </div>
      </div>
        <div class="col-span-2">
          <router-link  v-on:click="openSearch = false;"  :to='/search/ + searchText' class="btn btn-lg w-full text-center btn-search items-center self-center	content-center	">Search</router-link>
        </div>
    </div>
  </div>
  <div class="nav-gap" />
</template>

<script>
import NavItem from "@/components/nav/NavItem.vue";
import { ref } from "vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/vue";
import { MenuIcon, XIcon, ChevronUpIcon } from "@heroicons/vue/outline";
import { navigation } from "@/data";

const aboutSections = [
  {
    href: "/about/overview",
    label: "Overview",
  },
  { href: "/about/team-members", label: "team members" },
  { href: "/about/boards", label: "board members" },
  { href: "/about/committees", label: "Commitees" },
  {
    href: "/about/our-affiliates",
    label: "our affiliates",
  },
];

const antiracismSections = [
  {
    href: "/about/committees/anti-racism-roundtable/overview",
    label: "Overview",
  },
  { href: "/about/committees/anti-racism-roundtable/bipoc-initiative", label: "BIPOC Initiative" },
  { href: "/about/committees/anti-racism-roundtable/roundtable-members", label: "Roundtable Members" },
  { href: "/about/committees/anti-racism-roundtable/roundtable-timeline", label: "Roundtable Timeline" },
  {
    href: "/about/committees/anti-racism-roundtable/roundtable-resources",
    label: "Roundtable Resources",
  },
  {
    href: "/about/committees/anti-racism-roundtable/roundtable-newsroom",
    label: "Roundtable Newsroom",
  },
];

const covidSections = [
  {
    href: "/media/covid-19/news",
    label: "News & Updates",
  },
  { href: "/media/covid-19/vaccinations", label: "Vaccinations" },
  { href: "/media/covid-19/rapid-testing-documents", label: "Rapid Testing Documents" },
  { href: "/media/covid-19/rapid-testing-resources", label: "Rapid Testing Resources" },
  {
    href: "/media/covid-19/health-and-safety",
    label: "Health and Safety Best Practices",
  },
  {
    href: "/media/covid-19/posters-flyers",
    label: "Posters & Flyers",
  },
  {
    href: "/media/covid-19/important-information",
    label: "Important Information",
  },  
];

const mediaSections = [
  { href: "/media/in-the-news", label: "In the News" },
  {
    href: "/media/press-releases",
    label: "Press Releases",
  },
  { href: "/media/blog", label: "blog" },
  { href: "/media/videos", label: "Videos" },
  { href: "/media/newsletters", label: "newsletters" },
];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    MenuIcon,
    XIcon,
    NavItem,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
  },
  data(){
    return{
      searchText:"",
    }
  },
  setup() {
    const openSearch = ref(false);
    return { navigation, aboutSections, antiracismSections, covidSections, mediaSections, openSearch };
  },

};
</script>
